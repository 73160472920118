<template>
    <div>
        <div class="principal">
            <section>
                <form>
                    <center><img src="/img/alt60px_logo-gesto.png" style="height: 90px;"></center>
                    <div class="inputbox">
                        <input type="text" required
                            autocomplete="off"
                            v-model="usuario.username"
                            @keyup="formatearIngresoRut"
                            @keyup.enter="iniciarSesion"
                        >
                        <label for="">Rut Trabajador{{ !this.rutValidado?' (Invalido!)':'' }}</label>
                    </div>
                    <!--agregar {{ agregarPsw }}-->
                    <div class="inputbox" v-if="agregarPsw">
                        <input type="password" 
                            required
                            autocomplete="off"
                            v-model="usuario.passw"
                            @keyup.enter="iniciarSesion">
                        <label for="">Contraseña</label>
                    </div>
                    <div class="forget" v-show="agregarPsw">
                        <span><label for="" v-if="habilitadoRecordar"><input type="checkbox" v-model="recordarDatos"> {{ textosWeb.textoRecordar }}</label></span>
                        <span><a href="#" v-if="habilitadoOlvideContrasenia" @click="goReestablecerPsw">Olvidé mi contraseña</a></span>
                    </div>
                    <div><!--v-show="!showMensajeCambioPass && !showMensajeRutNoConfig"-->
                        <div v-show="isLogin">
                            <center>
                            <img src="/img/logo2.png" size="custom-size" :height="40" class="imgr mt-3 mb-3" /> Iniciando Sesión...
                            </center>
                        </div>
                        <CButton 
                            style="background-color:#0b7689; color:white;"
                            block shape="pill" 
                            class="mt-2 mb-2"
                            v-show="!isLogin"
                            @click="iniciarSesion">
                            Ingresar
                        </CButton>
                  
                        <hr>
                        <CButton block shape="pill" style="background-color:#29235C; color:white;" class="mt-2 mb-2" @click="cambioPortal">Ir a portal empresa</CButton>
                        
                    </div>

                    <div class="mt-3 mfs-auto version float-right">
                        <span class="mr-1" target="_blank">Versión Gesto <span>{{version}}</span></span>
                    </div>
                    <!--
                    
                    
                    
                    <div v-show="showMensajeCambioPass || showMensajeRutNoConfig">
                        <div v-show="showMensajeCambioPass" class="msjs">
                            <span v-show="!enviandoSolicitud">
                                <CIcon
                                    style="color: #f9b115;"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: msjCambiarPass
                                    }"
                                    :content="$options.freeSet.cilWarning"
                                />
                                <a href="#" style="color: #f9b115" v-if="showMensajeCambioPass" @click="cambiarContraseña()">Cambiar contraseña Aquí</a>
                            </span>
                            
                            <span v-show="enviandoSolicitud">
                                <CIcon class="imgr"
                                    v-show="!solicitudEnviada"
                                    :content="$options.freeSet.cilLoopCircular"
                                /> {{ msjSolicitandoCambio }}
                            </span>
                        </div>

                        <div v-show="showMensajeRutNoConfig" class="msjs">
                            <div v-show="ingresarRut">
                                <CInput
                                    :is-valid="rutValidarRegistrar"
                                    @blur="validarRutRegistrar"
                                    placeholder="Rut Usuario"
                                    autocomplete="Rut Usuario"
                                    v-model="rutRegistrar"
                                ></CInput>
                            </div>
                            <div>
                                <CIcon style="color: #f9b115"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: msjRutNoConfig
                                    }"
                                    :content="$options.freeSet.cilWarning"
                                />
                                <a href="#" style="color: #f9b115" @click="ingresarRut = !ingresarRut">{{(ingresarRut)?'Agregar Luego':'Agregue su Rut aquí'}}</a>
                            </div>
                        </div>

                        
                    
                    <CButton block shape="pill" 
                        class="m-1"
                        style="background-color:#0b7689; color:white; width: 100%;" 
                        :disabled="(ingresarRut && !rutValidarRegistrar) || (enviandoSolicitud && !solicitudEnviada)" 
                        @click="loguearUser">
                        Continuar
                    </CButton>
                        <hr>
                    </div>
                    
                    <div v-show="!showMensajeCambioPass && !showMensajeRutNoConfig">
                        <div v-show="isLogin">
                            <center>
                            <img src="/img/logo2.png" size="custom-size" :height="40" class="imgr mt-3 mb-3" /> Iniciando Sesión...
                            </center>
                        </div>
                        <CButton 
                            style="background-color:#0b7689; color:white;"
                            block shape="pill" 
                            class="mt-2 mb-2"
                            v-show="!isLogin"
                            @click="iniciarSesion">
                            Iniciar Sesión
                        </CButton>
                        <hr>
                        <CButton block shape="pill" style="background-color:#29235C; color:white;" class="mt-2 mb-2" @click="cambioPortal">Ir a portal Trabajador</CButton>
                        
                    </div>
                    
                    <div class="mt-3 mfs-auto version float-right">
                        <span class="mr-1" target="_blank">Versión Gesto <span>{{version}}</span></span>
                    </div>
                    -->
                </form>
            </section>
        </div>
    </div>
</template>
  
<script>
    import cons from '@/const'
    import axios from "axios"
    import * as util from '@/views/utils/utilsFunctions'

    export default {
        name: 'LoginTrabajador',
        
        data () {
        return {
                
            appName: cons.appName,
            version: cons.version,
            urlBase: cons.port + "://" + cons.ipServer,
            
            habilitadoRecordar: true,
            habilitadoOlvideContrasenia: true,
            recordarDatos: false,


                existeTokenLogin: false,
                isLogin: false,
                agregarPsw: false,
                
                //expiracionCookie: "8h",
                enviandoSolicitud: false,
                solicitudEnviada: false,
                msjSolicitandoCambio: "",

                //tipoMensaje: "success",
                //showMensaje : false,
                //mensajeLogin: "",
                showMensajeCambioPass: false,
                msjCambiarPass: "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
                cambiarPss: false,

                showMensajeRutNoConfig: false,
                msjRutNoConfig: "",
                rutValidarRegistrar: false,

                rutValidado: false,

                usuario: {
                username: ("token" in this.$route.query)?this.$route.query.user:null,
                passw:null,
                tokenSesion: ("token" in this.$route.query)?this.$route.query.token:null
                },

                logueoResp: null,
                rutRegistrar: null,
                ingresarRut: false,

                textosWeb: {
                    "textoRecordar": "Recordar",
                    "msjCambiarPass": "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
                },
        }
        },
        beforeMount() {
            
            if (localStorage.getItem('rememberMeTrabajador') === 'true') {
                this.usuario.username = localStorage.getItem('usernameTrabajador') || '';
                this.usuario.passw = localStorage.getItem('passwordTrabajador') || '';
                this.recordarDatos = true;
                this.agregarPsw = true
                this.formatearIngresoRut()
                //this.textosWeb.textoRecordar = "No Recordar"
            }
        
            cons.puertoQa = ("port" in this.$route.query) ? this.$route.query.port : "",
            this.validarSesion()

            if("token" in this.$route.query){
                this.existeTokenLogin = true
                this.agregarPsw = true
                this.formatearIngresoRut()
                this.mostrarNotificaciones(2, "Por favor ingrese su contraseña nueva")
                
            }
            //this.$route.query.token
        },
        methods: {
            
            formatearIngresoRut: function(){
                this.usuario.username = util.formatearRut(this.usuario.username)//this.formatoRut(this.usuario.username)
                this.rutValidado = util.validarRut(this.usuario.username)
                //this.validarRut()
            },
                
            goReestablecerPsw: function(){
                this.$router.push('ReestablecerPsw?user=trabajador')
            },
            cambioPortal: function(){
                this.$router.push('login')///pages/login
            },
            iniciarSesion: function(event){
                console.log("Revision asdasdasd", event.isTrusted)
                if(event.isTrusted == false){
                    return
                }
                if(!this.rutValidado){
                    this.mostrarNotificaciones(2, "Ingrese su rut correctamente para continuar porfavor")
                    return
                }

                if (this.recordarDatos) {
                    localStorage.setItem('usernameTrabajador', this.usuario.username);
                    localStorage.setItem('passwordTrabajador', this.usuario.passw);
                    localStorage.setItem('rememberMeTrabajador', true);
                } else{
                    localStorage.removeItem('usernameTrabajador', this.usuario.username);
                    localStorage.removeItem('passwordTrabajador', this.usuario.passw);
                    localStorage.removeItem('rememberMeTrabajador', true);
                }
                this.isLogin = true
                var url =  this.urlBase + "/log_in_trabajador/"

                if(this.usuario.username == null || this.usuario.username == ""){
                    //this.showMensaje = true
                    //this.mensajeLogin = "Ingrese correctamente sus datos"
                    //this.tipoMensaje = "danger"
                    this.mostrarNotificaciones(2, "Ingrese correctamente sus datos")
                }else{
                    if(!this.rutValidado){
                        //this.showMensaje = true
                        //this.mensajeLogin = "Ingrese correctamente el rut de la empresa"
                        //this.tipoMensaje = "danger"
                        this.mostrarNotificaciones(2, "Ingrese correctamente sus datos")
                    }

                    //this.showMensaje = false
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            usuario: {
                                username: this.usuario.username.replace(/\./g, ''),
                                passw: this.usuario.passw,
                                tokenSesion: this.usuario.tokenSesion
                            }
                            
                        }
                    }).then(result => {
                    if(result.data.solicitudContraseña){
                        this.mostrarNotificaciones(2, result.data.mensaje)
                    } else{
                        if(result.data.solicitarContrasenia){
                        this.mostrarNotificaciones(2, result.data.mensaje)
                        this.agregarPsw = true
                        }
                        else{
                        this.logueoResp = result.data.userLog
                        this.loguearUser()
                        }
                    }
                    this.isLogin = false
                        
                    }, error => {
                        this.mostrarNotificaciones(3, error.response.data.mensaje)
                        this.isLogin = false
                        
                    })
                    .finally(() => {
                    });
                
                }
                
            },
            loguearUser: function(){

                let cookieUsername = this.$cookie.get('userLogginToken')
                var uslog = this.logueoResp

                if(cookieUsername === null || cookieUsername === ""){

                this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });

                } else {

                if(uslog.tokenUsuario != JSON.parse(cookieUsername).tokenUsuario){
                    this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });
                }

                }
                this.mostrarNotificaciones(1, ("Bienvenido " + this.logueoResp.nombreTrabajador.split(" ")[0]))
                cons.rutaRedireccion = this.logueoResp.rutaRedireccion

                this.$router.push(this.logueoResp.rutaRedireccion);

            },
            /*formatoRut: function(rut){
                var sRut1 = rut.replace(/\./g,"").replace(/-/g,"");      //contador de para saber cuando insertar el . o la -
                var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
                var sInvertido = ""; //Guarda el resultado final del rut como debe ser
                var sRut = "";
                for(var i=(sRut1.length-1); i>=0; i-- ){
                    sInvertido += sRut1.charAt(i);
                    if (i == sRut1.length - 1 )
                        sInvertido += "-";
                    else if (nPos == 3)
                    {
                        sInvertido += ".";
                        nPos = 0;
                    }
                    nPos++;
                }
                for(var j = sInvertido.length - 1; j>= 0; j-- )
                {
                    if (sInvertido.charAt(sInvertido.length - 1) != ".")
                        sRut += sInvertido.charAt(j);
                    else if (j != sInvertido.length - 1 )
                        sRut += sInvertido.charAt(j);
                }
                //Pasamos al campo el valor formateado
                //rut.value = sRut.toUpperCase();
                console.log("sRut.toUpperCase();", sRut.toUpperCase())
                return sRut.toUpperCase();
            },*/
            

            validarSesion: function(){
                let cookieUsername = this.$cookie.get('userLogginToken')

                if(cookieUsername !== null ){
                
                this.$router.push(JSON.parse(cookieUsername).rutaRedireccion);
                }    
            },
            
            /*
            validarRutRegistrar: function(event){
                var rutCompleto = this.rutRegistrar
                    
                if(rutCompleto == "" || rutCompleto == null){
                this.rutValidarRegistrar = false
                } else{

                rutCompleto = rutCompleto.replace("‐","-");

                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                    this.rutValidarRegistrar =  false;
                    return
                }

                var tmp = rutCompleto.split('-');
                var digv = tmp[1]; 
                var rut = tmp[0];

                if ( digv == 'K' ) digv = 'k' ;

                this.rutValidarRegistrar = (this.validarDv(rut) == digv )
                }
            },
            
            

            
            
            */


        }
    }
</script>

<style scoped>
    
    /*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');*/
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'poppins',sans-serif;
    }
    img {
        max-width: 100%; /* Ajusta el ancho máximo de la imagen según sea necesario */
        height: 60px;
    }

    .principal {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-image: url("/img/mix.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    section {
        position: relative;
        max-width: 400px;
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        backdrop-filter: blur(55px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 3rem;
    }

    h1 {
        font-size: 2rem;
        color: #fff;
        text-align: center;
    }

    .inputbox {
        position: relative;
        margin: 20px 0;
        max-width: 310px;
        border-bottom: 2px solid #fff;
    }

    .inputbox label {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        color: #fff;
        font-size: 0.8rem;
        pointer-events: none;
        transition: all 0.5s ease-in-out;
    }

    input:focus ~ label, 
    input:valid ~ label {
        top: -5px;
    }
    /*input:invalid ~ label {
        
        color: #461A3B;
    }*/

    .inputbox input {
        width: 100%;
        height: 30px;
        background: transparent;
        border: none;
        outline: none;
        font-size: 1rem;
        padding: 0 35px 0 5px;
        color: #fff;
    }

    .inputbox .c-icon, .inputbox ion-icon {
        position: absolute;
        right: 8px;
        color: #fff;
        font-size: 1.2rem;
        top: 20px;
    }

    .forget {
        margin: 35px 0;
        font-size: 0.75rem;
        color: #fff;
        display: flex;
        justify-content: space-between;
    
    }

    .forget label {
        display: flex;
        align-items: center;
    }

    .forget label input {
        margin-right: 3px;
    }

    .forget a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
    }

    .forget a:hover {
        text-decoration: underline;
    }

    .msjs {
        margin: 5px 0;
        font-size: 0.75rem;
        color: #fff;
        display: flex;
        justify-content: space-between;
    
    }

    .msjs label {
        display: flex;
        align-items: center;
    }

    .msjs label input {
        margin-right: 3px;
    }

    .msjs a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
    }

    .msjs a:hover {
        text-decoration: underline;
    }

    button {
        width: 100%;
        height: 40px;
        border-radius: 40px;
        background-color: rgb(255, 255,255, 1);
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;
        transition: all 0.4s ease;
    }

    button:hover {
    background-color: rgb(255, 255,255, 0.5);
    }

    .register {
        font-size: 0.9rem;
        color: #fff;
        text-align: center;
        margin: 25px 0 10px;
    }

    .register p a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
    }

    .register p a:hover {
        text-decoration: underline;
    }
    .version{
        font-size: 0.6rem;
        text-decoration: none;
        color: #fff;
        font-weight: 100;
    }
</style>